import React, {useEffect} from "react"
import SelectDesignComponent from "./SelectDesignComponent";

export default function StepThreeComponent({project, updateProject, uiState, setUiState, trackingObj, jumpTo, isIFrame}){
    useEffect(() => {
        // if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
    }, []);
    return <div>
        {/*<h2>Schalterdesign auswählen</h2>*/}
        <div className="fs-container ptb-25 bg-white" style={{marginBottom: '-25px'}}>
            <SelectDesignComponent
                project={project}
                updateProject={updateProject}
                uiState={uiState}
                updateUiState={setUiState}
                trackingObj={trackingObj}
                jumpTo={jumpTo}
            />
        </div>
    </div>
}
