import React, {useEffect} from "react";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReactDOM from "react-dom";

export default function AddFloorComponent({onAddFloor}){

    const el = document.createElement('a');
    document.body.appendChild(el);
    const floorList = [
        {name: "Kellergeschoss", type: "934224a6-8d3a-4004-a0be-85c0b4d13cec", typePro: "934224a6-8d3a-4004-a0be-85c0b4d13cec", rooms: []},
        {name: "Erdgeschoss", type: "b84dbf25-11f8-46f7-9849-0c40d120c5b4", typePro: "b84dbf25-11f8-46f7-9849-0c40d120c5b4", rooms: []},
        {name: "Obergeschoss/Etage", type: "a54e5e51-9d7d-4d4c-a787-ef9502433343", typePro: "a54e5e51-9d7d-4d4c-a787-ef9502433343", rooms: []},
        {name: "Dachgeschoss", type: "21adeed2-57fc-458b-939e-24ffe96347c7", typePro: "21adeed2-57fc-458b-939e-24ffe96347c7", rooms: []},
    ];
    const [opened, setOpened] = React.useState(false);
    useEffect(() => {
        // if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
        // if ('parentIFrame' in window) {
        //     const btn = document.getElementsByClassName('theclick');
        //     setTimeout(() => {
        //         console.log('triggering first click');
        //         btn.click();
        //         setTimeout(() => {
        //             console.log('second click');
        //             btn.click();
        //         }, 100);
        //     },100);
        // }
    }, []);

    function onAddFloorInner(floor){
        onAddFloor(floor);
        handleClose();
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [marginLeft, setMarginLeft] = React.useState(0);

    const handleClick = (event) => {
        event.preventDefault(); event.stopPropagation();
        setMarginLeft(ReactDOM.findDOMNode(event.currentTarget).clientWidth);
        setAnchorEl(event.currentTarget);
        setOpened(true);
        return false;
    };

    const handleClose = () => {
        setOpened(false);
    };

    return <div className="addFloorWrapper">
        <div className="header">
            <div className="button theclick" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <AddCircleIcon />
                <span>Etage hinzufügen</span>
            </div>
            {'parentIFrame' in window
            ? <Menu
                id="simple-menu"
                anchorEl={el}
                keepMounted
                open={true}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px", visibility: opened ? 'visible' : 'hidden'}}
            >
                {floorList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addFloorFloorListItem" onClick={event => onAddFloorInner(r)}>{r.name}</MenuItem>)}
            </Menu>
            : <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={opened}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px", visibility: opened ? 'visible' : 'hidden'}}
            >
                {floorList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addFloorFloorListItem" onClick={event => onAddFloorInner(r)}>{r.name}</MenuItem>)}
            </Menu>}
        </div>
    </div>
}
