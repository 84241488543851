import React, {useEffect} from "react";
import {Tab, Tabs} from "@material-ui/core";
import schalterdesigns_knx from "./../data/schalterdesign.json"
import schalterdesigns_konventionell from "./../data/schalterdesign_konventionell.json"
import schalterdesigns_home from "./../data/schalterdesign_home.json"
import FloorDesignComponent from "./FloorDesignComponent";
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import f10 from '../images/F10.svg';
import f40 from '../images/F40.svg';
import f50 from '../images/F50.svg';
import f10b from '../images/F10_blue.svg';
import f40b from '../images/F40_blue.svg';
import f50b from '../images/F50_blue.svg';
// import iIconActive from "../images/info_icon_active.svg";
import iIcon from "../images/info_icon.svg";
import FilterIcon from "@material-ui/icons/Tune";
import Button from "@material-ui/core/Button";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";


export default function SelectDesignComponent({project, updateProject, uiState, updateUiState, trackingObj, jumpTo}){
    let schalterdesigns =  schalterdesigns_konventionell;
    if (project.projectType === "knx") { schalterdesigns = schalterdesigns_knx; }
    if (project.projectType === "home") { schalterdesigns = schalterdesigns_home; }
    const [infoOpened, setInfoOpened] = React.useState(false);
    const [roomsOpened, setRoomsOpened] = React.useState(false);
    const handleChangeSerie = (event, newValue) => {
        let index0 = schalterdesigns[newValue].operatingConcepts.findIndex(d => d.isDefault);
        let index0Selected = schalterdesigns[newValue].operatingConcepts.findIndex(d => d.name === (uiState.selectedOperatingConcept || ''));
        index0 = index0 > 0 ? (index0Selected > -1 ? index0Selected : index0) : 0;
        let index1 = schalterdesigns[newValue].operatingConcepts[index0].colorsTaster.findIndex(d => d.isDefault);
        index1 = index1 > 0 ? index1 : 0;
        const index2 = schalterdesigns[newValue].operatingConcepts[index0].colorsTaster[index1].colorsRahmen.findIndex(d => d.isDefault);
        const newState = {
            ...uiState,
            selectedSerie: newValue,
            selectedOperationConcept: index0,
            selectedColorTaster: index1,
            selectedColorRahmen: index2 > 0 ? index2 : 0,
            selectedOperatingConcept: schalterdesigns[newValue].operatingConcepts[index0].name,
        };
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    };

    const handleChangeOperationConcept = (event, newValue) => {
        let index1 = schalterdesigns[uiState.selectedSerie].operatingConcepts[newValue].colorsTaster.findIndex(d => d.isDefault);
        index1 = index1 > 0 ? index1 : 0;
        const index2 = schalterdesigns[uiState.selectedSerie].operatingConcepts[newValue].colorsTaster[index1].colorsRahmen.findIndex(d => d.isDefault);

        const newState = {
            ...uiState,
            selectedOperationConcept: newValue,
            selectedColorTaster: index1,
            selectedColorRahmen: index2 > 0 ? index2 : 0,
            selectedOperatingConcept: schalterdesigns[uiState.selectedSerie].operatingConcepts[newValue].name,
        };
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    };

    function setNewColor1(newValue){
        const index2 = schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[newValue].colorsRahmen.findIndex(d => d.isDefault);
        const newState = {
            ...uiState,
            selectedColorTaster: newValue,
            selectedColorRahmen: index2 > 0 ? index2 : 0,
        };
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    }
    const handleChangeColor1 = (event, newValue) => {
        setNewColor1(newValue);
    };
    const handleChangeColor2 = (event, newValue) => {
        const newState = {
            ...uiState,
            selectedColorRahmen: newValue,
        };
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    };

    function getIcon(serie, color, material, finish, isoId, type){
        var src = "https://www.projekt14.de/austausch/quick_j/";
        if (project.projectType !== 'home') { src += "JUNG_"; }
        else { src += 'home/schalter/'; }
        src += (color !== undefined) ? color : serie;
        src += ".png"
        return   src;
    }
    function getPicture(picture){
        var src = "https://www.projekt14.de/austausch/quick_j/";
        if (project.projectType === 'home') { src += 'home/schalter/'; }
        src += picture;
        src += ".png"
        return   src;
    }

    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : floor)});
    }
    function getLabelIcon(n) {
        var name = schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].name;
        if (n === 'F10') {
            if (name === 'F10') { return <img src={f10b} alt="" width="26" />; }
            return <img src={f10} alt="" width="26" />;
        }
        if (n === 'F40') {
            if (name === 'F40') { return <img src={f40b} alt="" width="26" />; }
            return <img src={f40} alt="" width="26" />;
        }
        if (n === 'F50') {
            if (name === 'F50') { return <img src={f50b} alt="" width="26" />; }
            return <img src={f50} alt="" width="26" />;
        }
    }
    function generateOperationConceptTabs(operatingConcepts){
        return operatingConcepts.map((item, ix) => <Tab key={item.id} label={item.name} icon={getLabelIcon(item.name)} />)
    }
    function generateTasterColorTabs(schalterdesign, colorsTaster){
        const isPicture = schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].picture;
        if (!isPicture) {
            return colorsTaster.map((item, ix) => <Tab key={"taster_" + ix} label={item.name}
                                                       icon={generateCompositeImageFromObjects(
                                                           "80px",
                                                           schalterdesign,
                                                           item,
                                                           item.colorsRahmen[0]
                                                       )}/>
            );
        } else {
            return colorsTaster.map((item, ix) => <Tab key={"taster_" + ix} label={item.name}
                                                       icon={generateCompositeImageFromObjects("80px", null, null, null, item.picture)}
                />
            );
        }

    }
    function generateRahmenColorTabs(schalterdesign, colorsRahmen){
        return colorsRahmen.map((item, ix) => <Tab key={"rahmen_" + ix} label={item.name} icon={<img src={getIcon(schalterdesign.id, item.colorId, item.materialId, item.finishId, item.isoId, "r")} alt="" style={{width: "80px"}}/>} />)
    }
    function changeTaster(direction, length) {
        switch (direction) {
            case 'prev':
                if (uiState.selectedColorTaster === 0) { return false; }
                setNewColor1(uiState.selectedColorTaster - 1);
                break;
            case 'next':
                if (uiState.selectedColorTaster === length - 1) { return false; }
                setNewColor1(uiState.selectedColorTaster + 1);
                break;
            default: return false;
        }
    }
    function generateCompositeImageWithRahmenColorTabs(schalterdesign, isPhone = false){
        const isPicture = schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].picture;
        return <div className="composite-image-container">
            <div style={{minWidth:"unset", width: "auto", padding: "0 50px 0 120px"}} className={"composite-container"}>
                <div className={"arrow-container"}>
                    <button
                        className={uiState.selectedColorTaster > 0 ? "arrow-left button" : "hidden"}
                            onClick={() => {changeTaster('prev', schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster.length)}}
                    >
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24"
                             aria-hidden="true">
                            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                        </svg>
                    </button>
                </div>
                {isPicture ?
                    <div className={"compositeImage"} style={{minWidth: "260px", minHeight: "260px", overflow: "hidden"}}>
                        {generateCompositePicture(isPicture)}
                    </div>
                    : <div className={"compositeImage"} style={{minWidth: "300px"}}>
                        {generateCompositeImage("100%", uiState.selectedSerie, uiState.selectedOperationConcept, uiState.selectedColorTaster, uiState.selectedColorRahmen)}
                    </div>
                }
                <div className={"arrow-container textLeft"}>
                    <button
                        className={uiState.selectedColorTaster < schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster.length - 1 ? "arrow-right button" : "hidden"}
                        onClick={() => {changeTaster('next', schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster.length)}}
                    >
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24"
                             aria-hidden="true">
                            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                        </svg>
                    </button>
                </div>
                <a href={"/"} onClick={(event) => {setInfoOpened(!infoOpened); event.preventDefault(); return false;}} className={"phoneShow phone-tooltip"}>
                    <img src={iIcon} width="32" height="32" style={{top: "10px"}} alt={"info"} />
                </a>
            </div>
            <div className={"description phoneNoShow"}>{getInfoBoxSelectedSerie(true)}</div>
            {infoOpened ? <div className={"description mt-m-30"}>{getInfoBoxSelectedSerie(true)}</div> : null}
        </div>;
    }
    function generateCompositeImage(width, selectedSerie, selectedOperationConcept, selectedColor1, selectedColor2){
        return generateCompositeImageFromObjects(
            width,
            schalterdesigns[selectedSerie],
            schalterdesigns[selectedSerie].operatingConcepts[selectedOperationConcept].colorsTaster[selectedColor1],
            schalterdesigns[selectedSerie].operatingConcepts[selectedOperationConcept].colorsTaster[selectedColor1].colorsRahmen[selectedColor2],
            schalterdesigns[selectedSerie].operatingConcepts[selectedOperationConcept].colorsTaster[selectedColor1].picture
        );
    }
    function generateCompositePicture(picture){
        return generateCompositeImageFromObjects(null, null, null, null, picture);
    }
    function generateCompositeImageFromObjects(width, selectedSerie, selectedColor1, selectedColor2, picture = null){
        if (!picture && !selectedColor1.picture) {
            return <div className={"imageTasterAndFrame"} style={{display: "inline-block", width: width, position: "relative"}}>
                <div className={"imageTaster compositeImageBackground"}></div>
                <img className={"imageFrame"} src={getIcon(selectedSerie.id, selectedColor2.colorId, selectedColor2.materialId, selectedColor2.finishId, selectedColor2.isoId, "r2")} style={{width: "100%", verticalAlign: "bottom"}} alt={""}/>
                <img className={"imageTaster"} src={getIcon(selectedSerie.id, selectedColor1.colorId, selectedColor1.materialId, selectedColor1.finishId, selectedColor1.isoId, "t")} style={{width: "100%", position: "absolute", top: 0, left: 0}} alt={""}/>
                {selectedColor1.designCover !== undefined ? <img className={"imageTaster"} src={getIcon(selectedSerie.id, selectedColor1.designCover, selectedColor2.materialId, selectedColor2.finishId, selectedColor2.isoId, "designCover")} style={{width: "100%", position: "absolute", top: 0, left: 0}} alt={""}/> : null}
            </div>
        } else {
            if (!picture) { picture = selectedColor1.picture; }
            if (width === '50px') {
                const pw = '60px';
                return <div className={"imageTasterAndFrame"} style={{display: "inline-block", width: pw, position: "relative", height: pw}}>
                    <img className={"imageFrame"} src={getPicture(picture)} style={{width: pw}} alt={""}/>
                </div>;
            }
            return <div className={"imageTasterAndFrame"} style={{display: "inline-block", width: width, position: "relative", height: width !== "80px" ? "unset" : width}}>
                <img className={"imageFrame"}
                     src={getPicture(picture)}
                     style={{width: width !== "80px" ? "380px" : "110px", marginLeft: width !== "80px" ? "-60px" : "-15px", marginTop: width !== "80px" ? "-75px" : "-15px"}} alt={""}/>
            </div>;
        }
    }

    // const HtmlTooltip = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--grau2)",
    //         color: "var(--schwarz)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '250px',
    //         minWidth: '150px',
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //         fontWeight: 'normal !important'
    //     },
    // }))(Tooltip);
    // const InfoOutlinedIconCustom = withStyles((theme) => ({
    //     root: {
    //         color: "var(--grau3)",
    //         fontSize: "18px",
    //         verticalAlign: "middle",
    //         paddingLeft: "5px"
    //     },
    // }))(InfoOutlinedIcon);
    // const HtmlTooltipDesign = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--weiss)",
    //         color: "var(--textColor)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '400px',
    //         minWidth: '150px',
    //         padding: "0px 7px",
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //     },
    // }))(Tooltip);
    // const InfoOutlinedIconCustomActive = withStyles((theme) => ({
    //     root: {
    //         color: "var(--captionColor)",
    //         fontSize: "18px",
    //         verticalAlign: "sub",
    //         alignContent: "right",
    //         paddingLeft: "5px"
    //     },
    // }))(InfoOutlinedIcon);
    function getConceptInfoText() {
        var txt = '';
        switch (schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].name) {
            case 'F10': txt = 'F10: Mit diesen Tastern bilden Technik und Design eine Einheit, die jeden Ansprüchen gerecht wird: Die Taster F 10 sehen aus wie klassische Lichtschalter, beherrschen aber smarte KNX Technik. Viele Funktionen in nur einem Gerät.'; break;
            case 'F40': txt = 'F40: Vier Funktionen in einem Gerät, statt vier einzelne Schalter an der Wand: Der Taster F 40 hat große Bedienflächen und jede ist mehrfach belegbar. Eine individuelle Beschriftung erleichtert die Handhabung.'; break;
            case 'F50': txt = 'F50: Großes Beschriftungsfeld für eine intuitive Bedienung: Der Taster F 50 überzeugt mit seinem Bedienkonzept. So schaltet z. B. die linke Taste (oder die obere, je nach Montage) das Licht ein, die rechte Tasterschaltet es aus. Jede Taste kann bis zu zwei Funktionen abdecken.'; break;
            default: txt = ''; break;
        }
        return txt;
    }
    function getInfoBoxSelectedSerie(justText = false){
        const toolTipText = <div className={justText ? "noTextTransform" : ""}>
            <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>Schalterprogramm</div>
                <div className={"col-6 info-txt"}>{schalterdesigns[uiState.selectedSerie].name}</div>
            </div>
            {project.projectType === 'knx' ? <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>Bedienkonzept
                    <TooltipWithButton props={{title: 'Bedienkonzept', message: getConceptInfoText()}} />
                </div>
                <div className={"col-6 info-txt"}>{schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].name}</div>
            </div> : null}
            <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>Taster
                    <TooltipWithButton props={{title: 'Taster', message: project.projectType !== 'home'
                            ? 'Taster können per Knopfdruck zwei Funktionen wie an/aus, hoch/runter, heller/dunkler inkl. Stopp-Telegramm oder zyklischem Senden ausführen.'
                            : 'Der JUNG HOME Taster 2fach ist ein 2-Kanal-Aufsatz für alle Systemeinsätze. Der Aufsatz besteht aus einer geteilten Wippe, die optisch wie eine Serienwippe wirkt. Die Wippe kann oben, unten und zusätzlich auch vollflächig betätigt werden. Auf jeder Wippe zeigt eine mehrfarbige Status-LED verschiedene Schaltzustände an. Zusätzlich ist ein Nachtmodus (Anzeige für 5 s nach Bedienung) möglich. Die Bedienung und die Konfiguration erfolgen mit einem mobilen Endgerät per Bluetooth®.'
                        }} />
                </div>
                <div className={"col-6 info-txt"}>
                    {schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorName}
                    {schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].materialName !== undefined ? ', ' + schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].materialName : null}
                    {schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].finishName !== undefined ? ', ' + schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].finishName : null}
                </div>
            </div>
            <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>Rahmen</div>
                <div className={"col-6 info-txt"}>
                    {schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].colorName}
                    {schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].materialName !== undefined ? ', ' + schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].materialName : null}
                    {schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].finishName !== undefined ? ', ' + schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].finishName : null}
                </div>
            </div>
        </div>;
        if (justText) { return toolTipText; }
        return <TooltipWithButton props={{title: '', message: toolTipText}} />;
    }
    function setForAllRooms(newState) {
        const currentDesign = {
            selectedSerie: schalterdesigns[newState.selectedSerie],
            selectedOperationConcept: schalterdesigns[newState.selectedSerie].operatingConcepts[newState.selectedOperationConcept],
            selectedColor1: schalterdesigns[newState.selectedSerie].operatingConcepts[newState.selectedOperationConcept].colorsTaster[newState.selectedColorTaster],
            selectedColor2: schalterdesigns[newState.selectedSerie].operatingConcepts[newState.selectedOperationConcept].colorsTaster[newState.selectedColorTaster].colorsRahmen[newState.selectedColorRahmen],
            picture: schalterdesigns[newState.selectedSerie].operatingConcepts[newState.selectedOperationConcept].colorsTaster[newState.selectedColorTaster].picture
        };
        let newFloors = [];
        project.floors.forEach((floor, idx) => {
            newFloors.push({
                ...floor,
                rooms: floor.rooms.map(r => {
                    return {
                        ...r,
                        selectedDesign: currentDesign,
                        manual: true
                    };
                })
            });
        });
        updateProject({...project, floors: newFloors});
    }
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "10px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            {'parentIFrame' in window
            ? <Popover id={id} open={true} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
            : <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>}
        </>;
    }

    return <>
        <div className={"padding-container"}>
            <div className="section-title">Schalterprogramm auswählen</div>
            <div className="section-text">Unser Designanspruch folgt einem einfachen Prinzip: Zeitlos, aber nicht langweilig. Modern, aber nicht modisch.
                <TooltipWithButton props={{noActive: true, title: 'Unser Designanspruch', message: 'Heute werden unsere Produkte weltweit als Garanten für hochwertige, verlässliche Qualität geschätzt. Unser Designanspruch folgt einem einfachen Prinzip: Zeitlos, aber nicht langweilig. Modern, aber nicht modisch. Ästhetisch und funktional in der Handhabung. Die Verbindung einer klaren Form mit der Sinnlichkeit des Materials wird in allen unseren Schalterprogrammen zu einem stimmigen Ganzen.'}} />
            </div>
        </div>
        <div className="gray-border wMargin" style={{paddingLeft: '0', marginBottom: '0'}}>
            <div className={"clearfix"} style={{marginTop: '15px', paddingLeft: '25px'}}>
                <div className={"SerienSelectBox"}>
                    <Tabs
                        value={uiState.selectedSerie}
                        onChange={handleChangeSerie}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"

                    >
                        {schalterdesigns.map(item => <Tab key={item.id} label={item.name} icon={<img src={getIcon(item.id)} alt="" style={{width: "60px"}}/>} />)}
                    </Tabs>
                </div>
            </div>
            <div className={"composite-with-rahmen phoneNoShow"}>
                {generateCompositeImageWithRahmenColorTabs(schalterdesigns[uiState.selectedSerie])}
            </div>
            <div className={"composite-with-rahmen phoneShow"}>
                {generateCompositeImageWithRahmenColorTabs(schalterdesigns[uiState.selectedSerie], true)}
            </div>
            <div className={"padding-container flex-row bedienkonzeptTabs" + (project.projectType === 'home' ? ' home' : '')}>
                <div className="section-title">{project.projectType !== 'konventionell' && project.projectType !== 'home' ? "Bedienkonzept" : "Farbauswahl"}</div>
                {project.projectType === 'knx' ? <div className={"onerow"}>
                    <Tabs
                        value={uiState.selectedOperationConcept}
                        onChange={handleChangeOperationConcept}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        {generateOperationConceptTabs(schalterdesigns[uiState.selectedSerie].operatingConcepts)}
                    </Tabs>
                    <TooltipWithButton props={{title: 'Bedienkonzept', message: '' +
                        'F10: Mit diesen Tastern bilden Technik und Design eine Einheit, die jeden Ansprüchen gerecht wird: Die Taster F 10 sehen aus wie klassische Lichtschalter, beherrschen aber smarte KNX Technik. Viele Funktionen in nur einem Gerät.<br/><br/>' +
                        'F40: Vier Funktionen in einem Gerät, statt vier einzelne Schalter an der Wand: Der Taster F 40 hat große Bedienflächen und jede ist mehrfach belegbar. Eine individuelle Beschriftung erleichtert die Handhabung.<br/><br/>' +
                        'F50: Großes Beschriftungsfeld für eine intuitive Bedienung: Der Taster F 50 überzeugt mit seinem Bedienkonzept. So schaltet z. B. die linke Taste (oder die obere, je nach Montage) das Licht ein, die rechte Tasterschaltet es aus. Jede Taste kann bis zu zwei Funktionen abdecken.'
                    }} />
                </div> : null}
            </div>
            <div className={"clearfix"}>
                <div style={{marginTop: "5px"}} className={"tasterNoTitle tasterColors"}>
                    {project.projectType === 'knx' ? <div className="section-title" style={{paddingLeft: '55px'}}>Farbauswahl</div> : null}
                    <Tabs
                        value={uiState.selectedColorTaster}
                        onChange={handleChangeColor1}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        {generateTasterColorTabs(
                            schalterdesigns[uiState.selectedSerie],
                            schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster
                        )}
                    </Tabs>
                </div>
            </div>
            <div className={"clearfix"}>
                <div className={""} style={{float: "none", width: "100%", marginBottom: "15px"}}>
                    { schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen.length < 2 ? null :
                    <div style={{marginTop: "45px"}}>
                        <div>Rahmen</div>
                        <Tabs
                            value={uiState.selectedColorRahmen}
                            onChange={handleChangeColor2}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {generateRahmenColorTabs(
                                schalterdesigns[uiState.selectedSerie],
                                schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen
                            )}
                        </Tabs>
                    </div> }
                </div>
            </div>
        </div>
        <div className={"padding-container background-grau zuweisung"} style={{paddingTop: "15px", paddingBottom: '32px'}}>
            <div className={"section-title"}>Auswahl zuordnen</div>
            <div className={"section-text border-bottom pb-15" + (!roomsOpened ? ' noBorder' : '')} style={{marginBottom: '-20px', flexDirection: 'column'}}>
                {project.projectType !== 'konventionell'
                    ? 'Sowohl das Design und die Materialität der Taster, als auch das Bedienkonzept können auf Wunsch von Raum zu Raum einzeln definiert werden.'
                    : 'Sowohl das Design als auch die Materialität der Taster können Sie auf Wunsch von Raum zu Raum einzeln definieren.'
                }
                <div className={"customOpener phoneShow" + (roomsOpened ? ' opened' : '')} style={{width: '100%', margin: '15px auto'}}>
                    <a href="/" onClick={(event) => {event.preventDefault(); setRoomsOpened(!roomsOpened); return false;} }>
                        <FilterIcon />
                        <div>{roomsOpened ? "Schließen" : "Bearbeiten"}</div>
                    </a>
                </div>
            </div>
            {roomsOpened || window.innerWidth >= 800 ? project.floors.map((floor, idx) =>
                <FloorDesignComponent
                    key={floor.id}
                    floor={floor}
                    data={schalterdesigns}
                    updateFloor={updateFloor}
                    generateCompositeImage={generateCompositeImageFromObjects}
                    currentDesign={
                        {
                            selectedSerie: schalterdesigns[uiState.selectedSerie],
                            selectedOperationConcept: schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept],
                            selectedColor1: schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster],
                            selectedColor2: schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen],
                            picture: schalterdesigns[uiState.selectedSerie].operatingConcepts[uiState.selectedOperationConcept].colorsTaster[uiState.selectedColorTaster].picture
                        }
                    }
                    opened={idx===0}
                    jumpTo={jumpTo}
                />
            ) : null}
        </div>
    </>

}
