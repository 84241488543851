
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import StartComponent from "./StartComponent";
import MyLoginComponent from "./MyLoginComponent";
import { OAuthPopup, useOAuth2 } from "@tasoskakour/react-use-oauth2";


export default function RouterComponent(){

    return <Routes>
        <Route path={"/"} element={<Navigate to={"/knx"} />}/>
        <Route path={"/:confType/myjung/callback"} element={<OAuthPopup />}/>
        <Route path={"/:confType/myjung/:code"} element={<MyLoginComponent/>}/>
        <Route path={"/:confType/myjung"} element={<MyLoginComponent/>}/>
        <Route path={"/:confType/:code"} element={<StartComponent />}/>
        <Route path={"/:confType/"} element={<StartComponent />}/>
    </Routes>

}
