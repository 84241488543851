import React from "react";
import initialProjectData from '../data/initialProjectData.json';
import knx_equipmentData_step2 from '../data/knx_ausstattungen_step2.json';
import knx_equipmentData_step4 from '../data/knx_ausstattungen_step4.json';
import konventionell_equipmentData_step2 from '../data/konventionell_ausstattungen_step2.json';
import konventionell_equipmentData_step4 from '../data/konventionell_ausstattungen_step4.json';
import home_equipmentData_step2 from '../data/home_ausstattungen_step2.json';
import home_equipmentData_step4 from '../data/home_ausstattungen_step4.json';
import StepOneComponent from "./StepOneComponent";
import StepTwoComponent from "./StepTwoComponent";
import logoSvg from "./../images/logo.svg"
import logoSvgLight from "./../images/alunio/alunio-logo.svg"
import StepThreeComponent from "./StepThreeComponent";
import StepFourComponent from "./StepFourComponent";
import StepFiveComponent from "./StepFiveComponent";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import Button from '@material-ui/core/Button';
import {createTheme, MuiThemeProvider, StepIcon, withStyles} from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import StepHomeComponent from "./StepHomeComponent";
import HomeSubPagesComponent from "./HomeSubpagesComponent";
import uarr from "../images/arrow_up.svg";
import uarrh from "../images/arrow_up_hover.svg";
// import darr from "../images/arrow_down.svg";
// import darrh from "../images/arrow_down_hover.svg";
import {v4 as uuidv4} from "uuid";
import roomList from "../data/availableRooms.json";
import {ProjectSaveService} from "../services/ProjectSaveService";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Checkbox from "@material-ui/core/Checkbox";
// import iIcon from "../images/info_icon.svg";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
// import FilterIcon from "@material-ui/icons/Filter";
import FilterIcon2 from "@material-ui/icons/Tune";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import documentTitle from "../services/documentTitle";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectComponent({isExpert = false, project, setProject, setSelectedProject, initProject, generateProject, currentStep, setCurrentStep, projectSelectList, getSchalterDesign, uiState, setUiState, getInitialUiState, startWithProject, isIFrame, trackingObj, isKonventionell, isHome, setTrackingId, isMyJung = false, jumpTo, offsetTopPos, scrollTop, setPrCode, prCode, lastSum, setLastSum}){

    const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
    // const [loadProjectOpen, setLoadProjectOpen] = React.useState(false);
    const [projectCode, setProjectCode] = React.useState('');

    const [gesamtsummeohnebeg, setGesamtsummeohnebeg] = React.useState(0);
    const [beg, setBeg] = React.useState(0);
    const [gesamtsumme, setGesamtsumme] = React.useState(0);
    const [foerderungs, setFoerderungs] = React.useState({foerderung1: false, foerderung2: false, foerderung3: false});
    const [showNav, setShowNav] = React.useState(false);
    const [showFoerderung, setShowFoerderung] = React.useState(false);

    if (project.projectType === 'konventionell') { documentTitle('Alunio Schalter Konfigurator'); }
    else if (project.projectType === 'knx') { documentTitle('Alunio KNX Konfigurator'); }
    else if (project.projectType === 'home') { documentTitle('Alunio HOME Konfigurator'); }

    // if (!isIFrame) { document.body.overflowY = 'auto'; }
    function navigateToStep(value){
        setCurrentStep(value)
        // console.log('scrollto')
        if ('parentIFrame' in window) {
            // window.parentIFrame.scrollToOffset(0, 0);
            window.parentIFrame.scrollTo(0, offsetTopPos - 64);
        } else {
            window.scrollTo(0, 0);
        }
        // if (trackingObj) {
        //     ProjectSaveService.saveTracking({step: value, trackingId: trackingObj.trackingId}, undefined, trackingObj)
        // }
    }
    // function navigateToBottom() {
    //     window.scrollTo(0, window.document.body.scrollHeight);
    // }

    function updateProject(projectIn){
        const wtop = window.document.documentElement.scrollTop;
        // console.log('scrolltop', document.body.scrollTop, window.document.documentElement.scrollTop)
        setProject({...projectIn}, true);
        // console.log('scrolltop', document.body.scrollTop, window.document.documentElement.scrollTop)
        // console.log('project set', window, window.parentIFrame)
        // if (window.parentIFrame === undefined) {
        //     setTimeout(() => {
        //         document.body.style.overflowY = 'auto';
        //         window.document.body.style.overflow = 'auto';
        //         // console.log('overflow unset', document.body.style.overflow, window.document.body.style.overflow)
        //         // console.log('scrolltop', document.body.scrollTop, window.document.documentElement.scrollTop)
        //         window.document.documentElement.scrollTo(0, wtop);
        //     }, 100);
        // }
    }

    function getEuqipmentDataStep2(projectType){
        switch (projectType) {
            case "knx":
                return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2);
            case "konventionell":
                return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2);
            case "home":
                return filterEquipmentForSelectecBuildingType(home_equipmentData_step2);
            default:
                return [];
        }
    }
    function getEuqipmentDataStep4(projectType){
        switch (projectType) {
            case "knx":
                return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4);
            case "konventionell":
                return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4);
            case "home":
                return filterEquipmentForSelectecBuildingType(home_equipmentData_step4);
            default:
                return [];
        }
    }
    function filterEquipmentForSelectecBuildingType(listIn){
        return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData[project.selectedMusterIx].type === l))));
    }

    function renderSwitch(param) {
        switch(param) {
            case 2:
                return <StepTwoComponent
                    project={project}
                    equipmentData={getEuqipmentDataStep2(project.projectType)}
                    updateProject={updateProject}
                    updateProjectWithNewLevel={p => updateProject(generateProject(p, undefined, '', true))}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 3:
                return <StepThreeComponent
                    project={project}
                    updateProject={updateProject}
                    uiState={uiState}
                    setUiState={setUiState}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 4:
                return <StepFourComponent
                    project={project}
                    equipmentData={getEuqipmentDataStep4(project.projectType)}
                    updateProject={updateProject}
                    updateProjectWithNewLevel={p => updateProject(generateProject(p))}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 5:
                return <StepFiveComponent
                    project={project}
                    updateProject={updateProject}
                    showProjectPrices={showProjectPrices}
                    foerderungs={foerderungs}
                    PriceBox={PriceBox}
                    trackingObj={trackingObj}
                    equipmentData2={getEuqipmentDataStep2(project.projectType)}
                    equipmentData4={getEuqipmentDataStep4(project.projectType)}
                    isKonventionell={isKonventionell}
                    isExpert={isExpert}
                    isHome={isHome}
                    isMyJung={isMyJung}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                    setPrCode={setPrCode}
                    prCode={prCode}
                    lastSum={lastSum}
                    setLastSum={setLastSum}
                />;
            case 1:
                return <StepOneComponent
                    project={project}
                    projectSelectList={projectSelectList}
                    setSelectedProject={setSelectedProject}
                    updateProject={updateProject}
                    startProject={startProject}
                    schalterdesign={getSchalterDesign(project.projectType)}
                    setProjectCode={setProjectCode}
                    projectCode={projectCode}
                    loadProjectFromCode={loadProjectFromCode}
                    trackingObj={trackingObj}
                    isIFrame={isIFrame}
                    jumpTo={jumpTo}
                    setPrCode={setPrCode}
                />;
            default:
                return <StepHomeComponent project={project}
                                          startProject={startProject} />
        }
    }
    function renderStartSubPages() {
        return <HomeSubPagesComponent project={project}
                                      startProject={startProject} />
    }
    function getDefaultEquipmentForSelectedLevel(type, selectedLevel){
        const list1 = roomList.filter(rl => (rl.type === type));
        const list2 = list1.length > 0 ? (list1[0]).defaultEquipment.filter(se => (se.level === selectedLevel)) : [];
        return list2.length > 0 ? list2[0].values : {};
    }
    function startProject(projectType, subProjectType = "neubau", resetDesign = false, navigateToOne = true) {
        GenerateRoomToAddService.resetDefaultDesignConfig();
        const schalterdesign = getSchalterDesign(projectType || project.projectType || "knx");
        const selectedLevel = typeof project.selectedLevel != "undefined" ? project.selectedLevel : 1;
        const initedProject = initProject(projectType, subProjectType);
        const floors = project.floors.length ? project.floors : initedProject.floors;
        // console.log('projectType', projectType, 'subProjectType', subProjectType, 'resetDesign', resetDesign)
        const floorToUse = floors.map(i => {
                return {
                    ...i,
                    id: i.id ? i.id : uuidv4(),
                    rooms: i.rooms.map(r => {
                        if (projectType) {
                            r = {...r, selectedDesign: undefined};
                        }
                        let equipment = getDefaultEquipmentForSelectedLevel(r.type, selectedLevel);
                        if (subProjectType === "umbau") {
                            const skipEquipment = ['telefon_datenanschluss', 'radio_datenanschluss_sat', 'telefon_datenanschluss', 'telefonanschlusss', 'radio_datenanschluss_kabel'];
                            Object.keys(equipment).forEach(key => {
                                equipment = {...equipment, [key]: skipEquipment.includes(key) ? equipment[key] : 0};
                            });
                            r = {...r, doorCount: 0, windowCount: 0, heatingCount: 0, floorheatingCount: 0};
                        } else if (r.originalValues) {
                            if (r.doorCount === 0 && r.windowCount === 0 && r.floorheatingCount === 0 && r.heatingCount === 0) {
                                r = {
                                    ...r,
                                    doorCount: r.originalValues.doorCount,
                                    windowCount: r.originalValues.windowCount,
                                    floorheatingCount: r.originalValues.floorheatingCount,
                                    heatingCount: r.originalValues.heatingCount
                                };
                            }
                        }
                        return {
                            ...r,
                            id: r.id ? r.id : uuidv4(),
                            selectedDesign: resetDesign
                                ? (r.selectedDesign ? r.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign))
                                : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign),
                            selectedEquipment: equipment
                        }
                    })
                };
            }
        );
        let newProject = {...initedProject, projectName: project.projectName, floors: floorToUse};
        // if (subProjectType === "umbau") { newProject = {...newProject, floors: []}; }
        updateProject(newProject);
        setUiState(getInitialUiState(projectType));
        if (navigateToOne){
            navigateToStep(1);
        }
    }

    const ColorButton1 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--grau3)",
            '&:hover': {
                backgroundColor:"var(--grau3)",
            },
            borderRadius: "8px",
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            textAlign: "center",
            margin: "20px",
            textTransform: "inherit"
        },
    }))(Button);
    const ColorButton2 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--color1)",
            '&:hover': {
                backgroundColor:"var(--color1)",
            },
            borderRadius: 0,
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            textAlign: "center",
            margin: "20px",
            textTransform: "inherit"
        },
    }))(Button);
    const ColorButtonToTop = withStyles((theme) => ({
        root: {
            color: "var(--color2)",
            backgroundColor: "transparent",
            cursor: "pointer",
            borderRadius: 0,
            width: "auto",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "-40px -10px 0 auto",
            textTransform: "inherit",
            border: "3px solid transparent",
            '&:hover': {
                backgroundColor: "transparent",
                color: "var(--color1)",
            },
            float: "right",
        },
    }))(Button);
    const ColorButton4 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--darkblue)",
            '&:hover': {
                backgroundColor:"var(--darkblue)",
            },
            cursor: "pointer",
            height: "45px",
            padding: "45px 15px",
            fontSize: "16px",
            marginTop: "0",
            textAlign: "left",
            lineHeight: "1.3",
            borderRadius: "0",
            textTransform: "inherit",
            width: "32%",
            marginRight: "1%",
        },
    }))(Button);
    const HeaderButton = withStyles((theme) => ({
        root: {
            color: "var(--textColor)",
            backgroundColor: "var(--weiss)",
            '&:hover': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)"
            },
            '&.active': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)"
            },
            cursor: "pointer",
            height: "63px",
            padding: "21px 30px",
            fontSize: "22px",
            margin: "0",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "auto",
        },
    }))(Button);
    function loadProjectFromCode(){
        if(projectCode){
            // setLoadProjectOpen(false);
            ProjectSaveService.loadProject(projectCode, startWithProject, project.isMyJung);
        }
    }

    const theme = createTheme({
        overrides: {
            MuiStepper:{
                root:{
                    padding: "10px",
                    background: "transparent",
                    marginTop: "20px",
                }
            },
            MuiStepIcon: {
                root:{
                    color: 'var(--grau3)',
                    "&$active": {
                        color: 'var(--color1)',
                    },
                    "&$completed": {
                        color: 'var(--color1)',
                    }
                }
            },
        },
    });

    function navigateToTop() {
        // console.log('scrollto')
        if ('parentIFrame' in window) {
            window.parentIFrame.scrollToOffset(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }
    function handleResetDialogOpen(e) {
        setResetDialogOpen(true);
        setShowNav(!showNav);
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if ('parentIFrame' in window) {
            const popups = document.getElementsByClassName('MuiDialog-paperScrollPaper');
            for(let i=0; i < popups.length; i++) {
                let currentPopup = popups[i];
                currentPopup.style.marginTop = (scrollTop - offsetTopPos) + 'px';
            }
        }
        return false;
    }
    function handleResetDialogClose(){
        setResetDialogOpen(false);
    }
    function handleResetDialogAgree(e){
        setTrackingId(null);
        // ProjectSaveService.saveTracking({trackingId: null, step: 1}, setTrackingId);
        setProject(initProject());
        setCurrentStep(1);
        setUiState(getInitialUiState());
        setResetDialogOpen(false);
        // console.log('scrollto')
        if ('parentIFrame' in window) {
            window.parentIFrame.scrollToOffset(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }
    function showProjectPrices(data) {
        setGesamtsummeohnebeg(data.gesamtsummeohnebeg);
        setBeg(data.beg);
        setGesamtsumme(data.gesamtsumme);
    }
    function setFoerderung1(v) {
        setTimeout(setFoerderungs({
            foerderung1: v,
            foerderung2: foerderungs.foerderung2,
            foerderung3: foerderungs.foerderung3
        }), 500);
    }
    function setFoerderung2(v) {
        setTimeout(setFoerderungs({
            foerderung1: foerderungs.foerderung1,
            foerderung2: v,
            foerderung3: foerderungs.foerderung3
        }), 500);
    }
    function setFoerderung3(v) {
        setTimeout(setFoerderungs({
            foerderung1: foerderungs.foerderung1,
            foerderung2: foerderungs.foerderung2,
            foerderung3: v
        }), 500);
    }
    function checker(opt) {
        if (opt === 1) { return <Checkbox checked={foerderungs.foerderung1} color="default" onChange={e => setFoerderung1(e.currentTarget.checked)} style={{padding: "0 5px 0 0"}} />;}
        if (opt === 2) { return <Checkbox checked={foerderungs.foerderung2} color="default" onChange={e => setFoerderung2(e.currentTarget.checked)} style={{padding: "0 5px 0 0"}} />; }
        if (opt === 3) { return <Checkbox className={"mobile-pr5"} checked={foerderungs.foerderung3} color="default" onChange={e => setFoerderung3(e.currentTarget.checked)} style={{padding: "0 0 0 0"}} />; }
    }
    function GetMenuIcon(prop) {
        if (showNav) { return <CloseIcon />; }
        return <MenuIcon />;
    }
    function PriceBox(props) {
        if (!props.opener && window.innerWidth > 800) {
            return <div className={"padding-container"} style={{paddingBottom: "15px", paddingTop: '15px'}}>
                <div className={"section-header"}>
                    <div className={"section-title"} style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>{project.projectType === 'konventionell' ? 'Materialkosten UVP' : 'Materialkosten UVP'}
                        <TooltipWithButton props={{noActive: true, title: '', message: 'Die Projektsumme inkl. MwSt. (19%); darin sind Materialkosten für Mediendosen laut HEA* und den ausgewählten Gebäudetyp enthalten, zzgl. Installationsarbeiten und mögliche zusätzliche Materialien.'}} />
                    </div>
                    <div className={"section-title"}>
                        {project.projectType === 'konventionell'
                            ? <strong>{gesamtsummeohnebeg} €*&nbsp;&nbsp;</strong>
                            : <span>{gesamtsummeohnebeg} €*&nbsp;&nbsp;</span>
                        }
                    </div>
                </div>
                {project.projectType === 'konventionell'
                    ? <div className={"section-footer"} style={{borderBottom: 'none', paddingBottom: '5px'}}>
                        <div className="section-text" style={{width: 'calc(100% - 200px)'}}>
                            <div className={"row"} style={{display: 'flex', width: '100%'}}>
                                <div className={"col"}>*&nbsp;</div>
                                <div className={"col"}>UVP inkl. 19% MwSt. Unverbindliche Preisempfehlung des
                                    Herstellers. Diese bezieht sich auf die reinen Materialkosten. Bitte beachten Sie,
                                    dass weitere Arbeitsschritte und Komponenten (z. B.
                                    Verteilung, Montage, Installation, Kabel oder Inbetriebnahme) im Preis nicht
                                    enthalten sind. Technische Änderungen vorbehalten.&nbsp;
                                    {project.subProjectType !== 'neubau'
                                        ? "Die Projektsumme versteht sich exklusive Mediendosen. Die Kosten dafür finden Sie in der Stückliste als Option aufgeführt."
                                        : "Die Projektsumme versteht sich inklusive der für Ihre Planung optimal berechneten Anzahl an Mediendosen."
                                    }</div>
                            </div>
                        </div>
                        <div className="section-text"></div>
                        <div className="section-text"></div>
                    </div>
                    : <div className={"section-footer"}
                           style={{borderBottom: '1px solid var(--textColor)', paddingBottom: '5px'}}>
                        <div className={"section-text"} style={{
                            fontSize: '16px',
                            textAlign: 'left',
                            padding: '0',
                            margin: '0',
                            width: 'calc(100% - 200px)'
                        }}>
                            <div className={"row phoneNoShow"} style={{display: 'flex', width: '100%'}}>
                                <div className={"col"}>*&nbsp;</div>
                                <div className={"col"}>UVP inkl. 19% MwSt. Die unverbindliche Preisempfehlung des
                                    Herstellers bezieht sich auf die reinen Materialkosten. Bitte beachten Sie, dass
                                    weitere Arbeitsschritte und Komponenten (z. B. Verteilung, Montage, Installation,
                                    Kabel oder Inbetriebnahme) im Preis nicht enthalten sind. Technische Änderungen
                                    vorbehalten. Die Projektsumme versteht sich inklusive der für Ihre Planung optimal
                                    berechneten Anzahl an Mediendosen.
                                </div>
                            </div>
                        </div>
                    </div>}
                {project.projectType !== 'konventionell'
                    ? <>
                        <div className={"section-header"}>
                            <div className={"section-title ppb"} style={{
                                width: 'auto',
                                marginBottom: '-10px',
                                marginTop: '10px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{display: 'inline'}}>Förderung</span>
                                <TooltipWithButton props={{noActive: true, title: '', message: 'Um die Förderung zu beantragen, benötigen Sie einen von der BAFA zertifizierten Energieberater. Der Antrag kann entweder direkt bei der BAFA gestellt werden oder bei der KFW zwecks Förderkredit mit gleichem Förderzuschuss (Stand 12/2022).'}} />
                            </div>
                            <div className={"section-title hideMobile"}></div>
                        </div>
                        <div className={"section-body mobileColumn"}>
                            <div className={"section-text"} style={{position: 'relative', top: '0'}}>
                                <div style={{display: 'inline-flex', flexDirection: 'column'}}>BEG Förderungen für
                                    smarte Funktionen<br/><a
                                        href="https://www.bafa.de/DE/Energie/Effiziente_Gebaeude/effiziente_gebaeude_node.html"
                                        target={"_blank"} rel={"noreferrer"} style={{color: 'var(--textColor)'}}>Mehr
                                        Informationen</a></div>
                                { project.projectType === 'knx' ?
                                    <TooltipWithButton props={{noActive: true, title: 'BEG Förderungen für smarte Funktionen', message: 'Alle smarten Funktionen Ihres KNX Smart Home sind mit 20% förderfähig.'}} />
                                    :
                                    <TooltipWithButton props={{noActive: true, title: 'BEG Förderungen für smarte Funktionen', message: 'Alle smarten Funktionen Ihres JUNG Home Projekt sind mit 20% förderfähig.'}} />
                                }
                                {checker(1)}
                            </div>
                            <div className={"section-text"} style={{textAlign: 'center'}}>
                                <div>mit Modernisierung der Heizungsanlage</div>
                                <TooltipWithButton props={{noActive: true, title: '', message: 'Die Modernisierung der Heizungsanlage (u.a. Wärmepumpe) mit smart geregelten Heizungsthermostaten und Steuerung (via App  | zentrales Panel) ist mit 35% förderfähig.'}} />
                                {checker(2)}
                            </div>
                            <div className={"section-text"} style={{textAlign: 'right', marginRight: '-4px'}}>
                                <div>mit Sanierungsfahrplan</div>
                                <TooltipWithButton props={{noActive: true, title: '', message: 'Die Erstellung eines Sanierungsfahrplans durch den Energieberater erhört die mögliche Fördersumme um 5%.'}} />
                                {checker(3)}
                            </div>
                            <div className={"section-text mobile-beg"}
                                 style={{color: 'var(--blueColor)'}}>{beg !== '0,00' ? '-' : ''} {beg} €&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </div>
                    </> : null}
                {project.projectType !== 'konventionell'
                    ? <div className={"section-footer"}>
                        <div
                            className={"section-text mobile-align-left title"}>Materialkosten {beg !== '0,00' ? 'mit Förderung' : ''}</div>
                        <div className={"section-text mobile-align-right"} style={{
                            fontWeight: "bold",
                            width: '200px',
                            marginLeft: '0'
                        }}>{beg !== '0,00' ? gesamtsumme : gesamtsummeohnebeg} €&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    </div> : null}
                <div className={"section-footer phoneShow"}
                     style={{color: 'var(--textColor)', marginTop: '15px', paddingRight: '15px'}}>
                    *UVP inkl. 19% MwSt. Die unverbindliche Preisempfehlung des Herstellers bezieht sich auf die reinen
                    Materialkosten. Bitte beachten Sie, dass weitere Arbeitsschritte und Komponenten (z. B. Verteilung,
                    Montage, Installation, Kabel oder Inbetriebnahme) im Preis nicht enthalten sind. Technische
                    Änderungen vorbehalten. Die Projektsumme versteht sich inklusive der für Ihre Planung optimal
                    berechneten Anzahl an Mediendosen.
                </div>
            </div>;
        }
        if (props.justNotice) {
            return <div className={"section-footer phoneShow"} style={{color: 'var(--textColor)', margin: '0', padding: '15px'}}>
                *UVP inkl. 19% MwSt. Die unverbindliche Preisempfehlung des Herstellers bezieht sich auf die reinen
                Materialkosten. Bitte beachten Sie, dass weitere Arbeitsschritte und Komponenten (z. B. Verteilung,
                Montage, Installation, Kabel oder Inbetriebnahme) im Preis nicht enthalten sind. Technische
                Änderungen vorbehalten. Die Projektsumme versteht sich inklusive der für Ihre Planung optimal
                berechneten Anzahl an Mediendosen.
            </div>;
        }
        if (props.opener) {
            return <div className={"padding-container mobile-pricebox"}
                        style={{paddingBottom: "15px", paddingTop: '15px'}}>
                <div className={"section-header"}>
                    <div className={"section-title"} style={{
                        display: 'flex',
                        alignItems: 'center', whiteSpace: 'nowrap'
                    }}>{project.projectType === 'konventionell' ? 'Materialkosten UVP' : 'Materialkosten UVP'}
                        <TooltipWithButton props={{noActive: true, title: '', message: 'Die Projektsumme inkl. MwSt. (19%); darin sind Materialkosten für Mediendosen laut HEA* und den ausgewählten Gebäudetyp enthalten, zzgl. Installationsarbeiten und mögliche zusätzliche Materialien.'}} />
                    </div>
                    <div className={"section-title"}>
                        {project.projectType === 'konventionell'
                            ? <strong>{gesamtsummeohnebeg} €*&nbsp;&nbsp;</strong>
                            : <span>{gesamtsummeohnebeg} €*&nbsp;&nbsp;</span>
                        }
                    </div>
                </div>
                {project.projectType !== 'konventionell'
                    ? <>
                        <div className={"section-header"} style={{flexDirection: 'column'}}>
                            <div className={"section-title ppb"} style={{
                                width: '100%',
                                marginBottom: '-20px',
                                marginTop: '10px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{display: 'inline'}}>Förderung</span>
                                <TooltipWithButton props={{noActive: true, title: '', message: 'Um die Förderung zu beantragen, benötigen Sie einen von der BAFA zertifizierten Energieberater. Der Antrag kann entweder direkt bei der BAFA gestellt werden oder bei der KFW zwecks Förderkredit mit gleichem Förderzuschuss (Stand 12/2022).'}} />
                            </div>
                            <div className={"customOpener phoneShow" + (showFoerderung ? ' opened' : '')} style={{width: 'calc(100% - 10px)'}}>
                                <a href="/" onClick={(event) => {event.preventDefault(); setShowFoerderung(!showFoerderung); return false;} }>
                                    <FilterIcon2 />
                                    <div>{showFoerderung ? "Schließen" : "Bearbeiten"}</div>
                                </a>
                            </div>
                        </div>
                        {showFoerderung ? <>
                                <div className={"section-body mobileColumn"}>
                                    <div className={"section-text"}>
                                        <div style={{display: 'inline-flex', flexDirection: 'column'}}>BEG Förderungen für
                                            smarte Funktionen<br/><a
                                                href="https://www.bafa.de/DE/Energie/Effiziente_Gebaeude/effiziente_gebaeude_node.html"
                                                target={"_blank"} rel={"noreferrer"} style={{color: 'var(--textColor)'}}>Mehr
                                                Informationen</a></div>
                                        { project.projectType === 'knx' ?
                                            <TooltipWithButton props={{noActive: true, title: 'BEG Förderungen für smarte Funktionen', message: 'Alle smarten Funktionen Ihres KNX Smart Home sind mit 20% förderfähig.'}} />
                                        :
                                            <TooltipWithButton props={{noActive: true, title: 'BEG Förderungen für smarte Funktionen', message: 'Alle smarten Funktionen Ihres JUNG Home Projekt sind mit 20% förderfähig.'}} />
                                        }
                                        {checker(1)}
                                    </div>
                                    <div className={"section-text"}>
                                        <div>mit Modernisierung der Heizungsanlage</div>
                                        <TooltipWithButton props={{noActive: true, title: '', message: 'Die Modernisierung der Heizungsanlage (u.a. Wärmepumpe) mit smart geregelten Heizungsthermostaten und Steuerung (via App  | zentrales Panel) ist mit 35% förderfähig.'}} />
                                        {checker(2)}
                                    </div>
                                    <div className={"section-text"}>
                                        <div>mit Sanierungsfahrplan</div>
                                        <TooltipWithButton props={{noActive: true, title: '', message: 'Die Erstellung eines Sanierungsfahrplans durch den Energieberater erhört die mögliche Fördersumme um 5%.'}} />
                                        {checker(3)}
                                    </div>
                                    <div className={"section-text mobile-beg"}
                                         style={{color: 'var(--blueColor)'}}>{beg !== '0,00' ? '-' : ''} {beg} €&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                </div>
                            </>
                            : null
                        }
                    </> : null}
                {project.projectType !== 'konventionell'
                    ? <div className={"section-footer"}>
                        <div
                            className={"section-text mobile-align-left title"}>Materialkosten {beg !== '0,00' ? 'mit Förderung' : ''}</div>
                        <div className={"section-text mobile-align-right"} style={{
                            fontWeight: "bold"
                        }}><span>{beg !== '0,00' ? gesamtsumme : gesamtsummeohnebeg} €&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>
                    </div> : null}
            </div>;
        }
        return <></>;
    }
    function CustomIcon(step) {
        return <div style={{width: '28px', height: '28px'}} className={"step-button" + (step.active ? " active" : "") + (step.completed ? " completed" : "")}>
            <div className={"label"}>{step.value}</div>
            <div className={"step-tooltip"} dangerouslySetInnerHTML={{ __html: step.txt }}></div>
        </div>;
    }
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "2px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            {'parentIFrame' in window
            ? <Popover id={id} open={true} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
            : <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>}
        </>;
    }
    // if (!isIFrame) { document.body.style.overflow = 'unset'; }
    if (currentStep > 0) {
        return <MuiThemeProvider theme={theme}>
            <div className={"header-container" + (isIFrame ? ' hidden' : '')}>
                <div className="header-fixed desktop">
                    <HeaderButton className="active">Planung</HeaderButton>
                    <HeaderButton onClick={handleResetDialogOpen}>Neue Planung</HeaderButton>
                    <div className="logo-container">
                        <img src={logoSvgLight} alt="logo" style={{height:'45px',marginTop: '5px'}}/>
                    </div>
                    <HeaderButton href={"/"} target={"_blank"} rel="noreferrer">Unsere Fachpartner</HeaderButton>
                    <HeaderButton href={"/"} target={"_blank"} rel="noreferrer">Kontakt</HeaderButton>
                    <Dialog
                        open={resetDialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleResetDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" color={"inherit"}>
                                Wollen Sie wirklich eine neue Planung?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent: "center"}}>
                            <div onClick={handleResetDialogClose} color="primary"
                                 className={"modalButton disagree"}>
                                Abbrechen
                            </div>
                            <div onClick={handleResetDialogAgree} color="primary" className={"modalButton agree"}>
                                Neue Planung
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
                <div className="header-fixed mobile">
                    <div className="logo-container">
                        <img src={logoSvgLight} alt="logo"/>
                    </div>
                    <div className={"nav-bar"}>
                        <div className={"container"}>
                            <a href={"/"} className='nav-toggler' onClick={(event) => {setShowNav(!showNav); event.preventDefault(); return false;}}>
                                <GetMenuIcon props={{open:showNav}} />
                            </a>
                        </div>
                    </div>
                    <div className={"nav-body" + (showNav ? " visible" : " hidden")}>
                        <div className={"menu-title"}>Planung</div>
                        <a href={"/"} onClick={(event) => handleResetDialogOpen(event)}>Neue Planung</a>
                        <a href={"/"} target={"_blank"} rel="noreferrer">Unsere Fachpartner</a>
                        <a href={"/"} target={"_blank"} rel="noreferrer">Kontakt</a>
                        <div className={"spacer"}></div>
                        <a href={"/"} target={"_blank"} rel="noreferrer">Impressum </a>
                        <a href={"/"} target={"_blank"} rel="noreferrer">Datenschutz</a>
                        <a href={"/"} target={"_blank"} rel="noreferrer">Nutzungsbedingungen</a>
                    </div>
                    <Dialog
                        open={resetDialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleResetDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" color={"inherit"}>
                                Wollen Sie wirklich eine neue Planung?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent: "center"}}>
                            <div onClick={handleResetDialogClose} color="primary"
                                 className={"modalButton disagree"}>
                                Abbrechen
                            </div>
                            <div onClick={handleResetDialogAgree} color="primary" className={"modalButton agree"}>
                                Neue Planung
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            <div className="steps-container">
                <div className="steps-fixed">
                    <Stepper activeStep={currentStep - 1} alternativeLabel style={{marginLeft: "auto", marginRight: "auto"}}>
                        <Step key="1"
                              onClick={e => currentStep >= 2 ? navigateToStep(1) : null}
                              className={currentStep >= 2 ? "mouseover-hand" : ""}
                        >
                            <StepIcon icon={<CustomIcon value="1" active={currentStep === 1} completed={currentStep > 1} txt={"Gebäudestruktur<br/>anlegen"} />}></StepIcon>
                            {/*<StepLabel>Gebäudestruktur<br/>anlegen</StepLabel>*/}
                        </Step>
                        <Step key="2"
                              onClick={e => currentStep >= 3 || currentStep === 1 ? navigateToStep(2) : null}
                              className={[1, 3, 4, 5].includes(currentStep) ? "mouseover-hand" : ""}
                        >
                            <StepIcon icon={<CustomIcon value="2" active={currentStep === 2} completed={currentStep > 2} txt={"Ausstattungsumfang<br/>definieren"} />}></StepIcon>
                        </Step>
                        <Step key="3"
                              onClick={e => currentStep >= 4 || currentStep === 2 ? navigateToStep(3) : null}
                              className={[2, 4, 5].includes(currentStep) ? "mouseover-hand" : ""}
                        >
                            <StepIcon icon={<CustomIcon value="3" active={currentStep === 3} completed={currentStep > 3}
                                                        txt={project.projectType === 'konventionell' ? "Schalterdesign<br/>auswählen" : "Tasterdesign<br/>auswählen"} />}></StepIcon>
                        </Step>
                        <Step key="4"
                              onClick={e => currentStep >= 5 || currentStep === 3 ? navigateToStep(4) : null}
                              className={([3, 5].includes(currentStep) ? "mouseover-hand" : "") + " phone-right"}
                        >
                            <StepIcon icon={<CustomIcon value="4" active={currentStep === 4} completed={currentStep > 4}
                                                         txt={project.projectType === 'konventionell' ? "Weitere&nbsp;Ausstattung<br/>auswählen" : "Smarte&nbsp;Zusatzausstattung<br/>auswählen"} />}></StepIcon>
                        </Step>
                        <Step key="5"
                              onClick={e => currentStep === 4 ? navigateToStep(5) : null}
                              className={(currentStep === 4 ? "mouseover-hand" : "") + " phone-right"}
                        >
                            <StepIcon icon={<CustomIcon value="5" active={currentStep === 5} completed={currentStep > 5} txt={"Produktlisten + Preis<br/>anzeigen"} />}></StepIcon>
                        </Step>
                    </Stepper>
                </div>
            </div>
            {renderSwitch(currentStep)}
            {currentStep === 5 ? <>
                <div className={"navButtons" + (isIFrame ? ' isiframe' : '')} style={{maxWidth: "1116px", margin: "0 auto", paddingTop: '55px', display: 'flex', alignItems: "center", position: 'relative'}}>
                    {isIFrame ? <>
                        <ColorButton1 className={"grau"} style={{opacity: currentStep === 1 ? '0' : 'unset', pointerEvents: currentStep === 1 ? 'none' : 'unset', width: '218px'}} onClick={e => navigateToStep(currentStep - 1)}>Zurück</ColorButton1>
                        <ColorButton2 style={{opacity: currentStep === 5 ? '0' : 'unset', pointerEvents: currentStep === 5 ? 'none' : 'unset', width: '218px'}} onClick={e => navigateToStep(currentStep + 1)}>Weiter</ColorButton2>
                        <ColorButton1 className={"grau"} style={{width: '218px'}} onClick={handleResetDialogOpen}>Neue&nbsp;Planung</ColorButton1>
                    </> : <>
                        <ColorButton1 className={"grau"} style={{width: '218px', margin: '0 auto'}} onClick={e => navigateToStep(currentStep - 1)}>Zurück</ColorButton1>
                    </>}
                    <ColorButtonToTop onClick={e => navigateToTop()} className="button woText" style={{position: 'absolute', top: '32px', right: 0}}>
                        <img className="icon-to-page-bottom" src={uarr} alt={""}/>
                        <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""}/>
                    </ColorButtonToTop>
                </div>
            </>: null}
            {currentStep === 5 ?
                <div className="pricebox-sticky-bottom">
                    <PriceBox />
                    {window.innerWidth < 800 ? <PriceBox justNotice={true} /> : null}
                </div> : null}
            <div className={"footer-container" + (isIFrame ? ' isiframe': '')}>
                {currentStep < 5  ? <div className="navButtons">
                    {isIFrame
                        ? <>
                            <ColorButton1 className={"grau"} style={{opacity: currentStep === 1 ? '0' : 'unset', pointerEvents: currentStep === 1 ? 'none' : 'unset'}} onClick={e => navigateToStep(currentStep - 1)}>Zurück</ColorButton1>
                            <ColorButton2 style={{opacity: currentStep === 5 ? '0' : 'unset', pointerEvents: currentStep === 5 ? 'none' : 'unset'}} onClick={e => navigateToStep(currentStep + 1)}>Weiter</ColorButton2>
                            <ColorButton1 className={"grau"} onClick={handleResetDialogOpen}>Neue&nbsp;Planung</ColorButton1>
                        </>
                        : <>
                            {currentStep > 1 ?
                                <ColorButton1 className={"grau"}
                                              onClick={e => navigateToStep(currentStep - 1)}>Zurück</ColorButton1> : null}
                            {currentStep < 5 ?
                                <ColorButton2 className={currentStep === 1 ? 'margin-auto' : ''}
                                    onClick={e => navigateToStep(currentStep + 1)}>Weiter</ColorButton2> : null}
                            {currentStep < 5 ?
                                <ColorButtonToTop onClick={e => navigateToTop()} className="button woText">
                                    <img className="icon-to-page-bottom" src={uarr} alt={""}/>
                                    <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""}/>
                                </ColorButtonToTop> : null}
                        </>
                    }
                </div> : null}
                <div className={"footer" + (isIFrame ? ' hidden' : '')}>
                    <div className="footer-box">
                        {/*<div>*/}
                        {/*    Jung by FEELSMART. ©{new Date().getFullYear()}*/}
                        {/*</div>*/}
                        <div>
                            © Alunio by FEELSMART.
                        </div>
                        <div className={"footerMenu"}>
                            <a href={"/"} target={"_blank"} rel="noreferrer">Impressum </a>
                            <a href={"/"} target={"_blank"} rel="noreferrer">Datenschutz</a>
                            <a href={"/"} target={"_blank"}
                               rel="noreferrer">Nutzungsbedingungen</a>
                        </div>
                    </div>
                </div>
            </div>
        </MuiThemeProvider>
    }
    return <MuiThemeProvider theme={theme}>
        <div className="fs-container noBackground" style={{paddingTop: "0"}}>
            <div className="logoBox" style={{paddingBottom: "40px", paddingTop: "40px"}}>
                <img src={logoSvg} alt="logo" height="20px"/>
            </div>
        </div>
        <div className="fs-container noBackground" style={{paddingBottom: "0", paddingTop: "0", marginTop: "-119px"}}>
            {renderSwitch(currentStep)}
        </div>
        <div className="subpages">
            {renderStartSubPages()}
        </div>
        <div className="subpages">
            <div className="box subpage" style={{width: "100%"}}>
                <div className="page">
                    <div className="buttonsHolder bottom" style={{width: "100%"}}>
                        <ColorButton4 onClick={startProject}>
                            <span className={"headerMenuItem"}><strong>Konventionell</strong><br/>planen</span>
                        </ColorButton4>
                        <ColorButton4 onClick={startProject} style={{marginLeft: "1%"}}>
                            <span className={"headerMenuItem"}><strong>ETS-System</strong><br/>planen</span>
                        </ColorButton4>
                        <ColorButton4 onClick={startProject} style={{marginRight: "0", marginLeft: "1%"}}>
                            <span className={"headerMenuItem"}><strong>Easy-System</strong><br/>planen</span>
                        </ColorButton4>
                    </div>
                </div>
            </div>
        </div>
        <div className="fs-container noBackground">
            <div className={"footer fs-box-30 clearfix"}>
                {/*<div style={{float: "left"}}>*/}
                {/*    Jung by FEELSMART. ©{new Date().getFullYear()}*/}
                {/*</div>*/}
                <div style={{float: "left"}}>
                    © Jung by FEELSMART.
                </div>
                <div className={"footerMenu"} style={{float: "right"}}>
                    <a href={"/"} target={"_blank"} rel="noreferrer">Impressum </a>
                    <a href={"/"} target={"_blank"} rel="noreferrer">Datenschutz</a>
                    <a href={"/"} target={"_blank"}
                       rel="noreferrer">Nutzungsbedingungen</a>
                </div>
            </div>
        </div>
    </MuiThemeProvider>

}
