
export class ProjectSaveService{

    static version = "0.1";

    static getDomain(){
        return process.env.REACT_APP_QUICK_BACKEND_URL || 'https://alunio.b-onweb.de';
    }

    static saveProject(project, projectPro, actionsList, setPlanungsCode){
        if (!projectPro) { projectPro = actionsList.generatedProject; }
        fetch(
            this.getDomain() + "/configurator-api/quickplaner/save",
            {
                method: "post",
                mode: "cors",
                body: JSON.stringify({version: this.version, dataP: projectPro, dataQ: project})
            }
        ).then(res => res.json()).then(result => {
            setPlanungsCode(result.code)
        }).catch(console.log);
    }

    static loadProject(code, startWithProject, isMyJung = false){
        fetch(
            this.getDomain() + "/configurator-api/quickplaner/load/" + code,
            {
                method: "get",
                mode: "cors"
            }
        ).then(res => res.json()).then(result => {
            startWithProject({...result.dataQ, isMyJung: isMyJung, loadedFromCode: true})
        }).catch(e => alert("Code not supported!"));
    }

    static saveTracking(params, setTrackingId, trackingObj = null) {
        return false;
        // fetch(
        //     this.getDomain() + '/configurator-api/quickplaner/tracking',
        //     {
        //         method: 'post',
        //         mode: "cors",
        //         body: JSON.stringify(params)
        //     }
        // ).then(res => res.json())
        // .then(result => {
        //     if (trackingObj) { Object.assign(trackingObj, params, {trackingId: result.trackingId}); }
        //     else { trackingObj = Object.assign(params, {trackingId: result.trackingId}); }
        //     if (typeof setTrackingId === "function") { setTrackingId(trackingObj); }
        // }).catch(e => console.log('tracking post error', e));
    }
}
