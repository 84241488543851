import React from "react";
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LabelBox from "./LabelBox/LabelBox";
import {Tooltip, withStyles} from "@material-ui/core";
import uarrh from "../images/arrow_up_hover.svg";
import darrh from "../images/arrow_down_hover.svg";
import Button from "@material-ui/core/Button";

export default function FloorDesignComponent({floor, updateFloor, data, generateCompositeImage, currentDesign, opened, jumpTo}){
    const [open, setOpen] = React.useState(opened);
    const [roomHistorie, setRoomHistorie] = React.useState({});

    function onOpenClose(e){
        e.preventDefault(); e.stopPropagation();
        setOpen(!open);
        // if ('parentIFrame' in window) {
        //     jumpTo(e.pageY);
        // }
        return false;
    }
    function updateRoom(room){
        setRoomHistorie(
            {
                ...roomHistorie,
                [room.id]: room.selectedDesign
            }
        );
        updateFloor({...floor, rooms: floor.rooms.map(r => r.id !== room.id ? r : {...room, selectedDesign: currentDesign, manual: true})});
    }
    // function resetRoom(room){
    //     updateFloor({...floor, rooms: floor.rooms.map(r => r.id !== room.id ? r : {...room, selectedDesign: roomHistorie[room.id], manual: false})});
    //     setRoomHistorie(
    //         {
    //             ...roomHistorie,
    //             [room.id]: undefined
    //         }
    //     );
    // }
    function selectForFloor(floor){
        let tmpList = [];
        floor.rooms.forEach(
            room => {
                tmpList[room.id] = room.selectedDesign;
            }
        );

        setRoomHistorie(
            {
                ...roomHistorie,
                ...tmpList
            });

        updateFloor({...floor, rooms: floor.rooms.map(r => {return {...r, selectedDesign: currentDesign, manual: false}})});
    }
    // function resetForFloor(floor){
    //     updateFloor({...floor, rooms: floor.rooms.map(r => {return {...r, selectedDesign: roomHistorie[r.id] !== undefined ? roomHistorie[r.id] : r.selectedDesign}})});
    //     let tmpList = [];
    //     floor.rooms.forEach(
    //         room => {
    //             tmpList[room.id] = undefined;
    //         }
    //     );
    //
    //     setRoomHistorie(
    //         {
    //             ...roomHistorie,
    //             ...tmpList
    //         });
    // }

    function generateRoomList(){
        const designCheck = differentRoomDesigns(floor);
        return floor.rooms.map(r => generateRoom(r, designCheck));
    }

    function compareColor(color1, color2){
        if(
            color1.colorId !== color2.colorId
            || color1.materialId !== color2.materialId
            || color1.finishId !== color2.finishId
            || color1.isoId !== color2.isoId
        ){
            return false;
        }
        return true;
    }

    // function ResetIcon() {
    //     return <div className={"resetIcon"}>
    //         <svg viewBox="0 0 216.21 158.76" fill="#3c95b3" width="20" height="24">
    //             <path d="M129.73,47.62c17.92,0,33.21,4.65,45.87,13.94,12.67,9.3,18.99,20.53,18.99,33.68s-6.32,24.39-18.99,33.69c-12.65,9.29-27.94,13.94-45.87,13.94H54.05v15.88h75.68c23.86,0,44.24-6.21,61.14-18.62,16.89-12.4,25.34-27.37,25.34-44.9s-8.42-32.49-25.26-44.89c-16.86-12.41-37.25-18.62-61.22-18.62H54.05V0L0,39.69l54.05,39.69v-31.76h75.68Z"/>
    //         </svg>
    //     </div>;
    // }
    function ApplyIcon() {
        return <div className={"resetIcon"}>
            <svg viewBox="0 0 216.21 158.76" fill="#000000" width="20" height="24">
                <polygon points="138.5 9.34 120.81 0 59.78 115.69 13.32 74.21 0 89.13 65.53 147.65 138.5 9.34"/>
            </svg>
        </div>;
    }
    function differentRoomDesigns(floor) {
        let found = false; let firstDesign;
        floor.rooms.forEach(room => {
            if (!firstDesign) { firstDesign = room.selectedDesign; }
            if (room.selectedDesign.selectedSerie.id !== firstDesign.selectedSerie.id
                || !compareColor(room.selectedDesign.selectedColor1, firstDesign.selectedColor1)
                || !compareColor(room.selectedDesign.selectedColor2, firstDesign.selectedColor2)) {
                found = true;
            }
        });
        return found;
    }

    function generateRoom(room, check){
        // const selectDesignDisabled = roomHistorie[room.id] ? (
        //     room.selectedDesign.selectedSerie.id !== roomHistorie[room.id].selectedSerie.id
        //     || !compareColor(room.selectedDesign.selectedColor1, roomHistorie[room.id].selectedColor1)
        //     || !compareColor(room.selectedDesign.selectedColor2, roomHistorie[room.id].selectedColor2
        //     || room.selectedDesign.selectedOperationConcept.id !== roomHistorie[room.id].selectedOperationConcept.id)
        // ) : false;
        var contentSelectColumn = "";
        switch(true){
            // case roomHistorie[room.id] !== undefined && selectDesignDisabled:
            //     contentSelectColumn = <div className={"buttonResetDesignForRoom"} onClick={e => resetRoom(room)}><ResetIcon /> Rückgängig machen</div>;
            //     break;
            // case room.manual && selectDesignDisabled:
            //     contentSelectColumn = <div className={"buttonResetDesignForRoom"} onClick={e => resetRoom(room)}><ResetIcon /> Rückgängig machen</div>;
            //     break;
            // case selectDesignDisabled:
            //     contentSelectColumn = <div className={"buttonResetDesignForRoom"} onClick={e => resetRoom(room)}><ResetIcon /> Rückgängig machen</div>;
            //     break;
            case roomHistorie[room.id] !== undefined:
                if (compareColor(room.selectedDesign.selectedColor1, currentDesign.selectedColor1)) {
                    contentSelectColumn = <div className={"buttonSelectDesignForRoom"} onClick={e => updateRoom(room)}><ApplyIcon/> Auswahl bestätigt</div>;
                } else {
                    contentSelectColumn = <div className={"buttonSelectDesignForRoom"} onClick={e => updateRoom(room)}>Auswahl anwenden</div>;
                }
                break;
            default:
                if (compareColor(room.selectedDesign.selectedColor1, currentDesign.selectedColor1)) {
                    contentSelectColumn = <div className={"buttonSelectDesignForRoom"} onClick={e => updateRoom(room)}><ApplyIcon/> Vorausgewählt</div>;
                } else {
                    contentSelectColumn = <div className={"buttonSelectDesignForRoom"} onClick={e => updateRoom(room)}>Auswahl anwenden</div>;
                }
        }

        return <div className="roomRow row bg-white" key={room.id} style={{lineHeight:"40px", verticalAlign:"middle"}}>
            <div className="col-3 contextBoxHover">
                <div className="openClose"> </div>
                <LabelBox value={room.name} editAble={false} isBold={false}/>
            </div>
            <div className={"text-center col-2"}>
                {generateSelectedColumn(room)}
            </div>
            <div className={"text-center col-4"} style={{padding:"5px"}}>
                {contentSelectColumn}
                {/*{(selectDesignDisabled) ? <div>ausgewählt</div> : <div className={"buttonSelectDesignForRoom"} onClick={e => updateRoom(room)}>auswählen</div>}*/}
            </div>
        </div>;
    }
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--schwarz)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '250px',
            minWidth: '120px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            fontWeight: 'normal !important'
        },
    }))(Tooltip);

    function generateSelectedColumn(room){
        const selectedSerie = room.selectedDesign.selectedSerie;
        const selectedOperationConcept = room.selectedDesign.selectedOperationConcept;
        const selectedColor1 = room.selectedDesign.selectedColor1;
        const selectedColor2 = room.selectedDesign.selectedColor2;
        let picture;
        Object.keys(room.selectedDesign.selectedOperationConcept.colorsTaster).map(key => {
            const color = room.selectedDesign.selectedOperationConcept.colorsTaster[key];
            if (color.colorId === selectedColor1.id) {
                color.colorsRahmen.map(rahmen => {
                    if (rahmen.colorId === selectedColor2.id) {
                        picture = color.picture;
                    }
                    return rahmen;
                });
            }
            return key;
        });
        if (!picture && selectedColor1.picture) { picture = selectedColor1.picture; }
        if(
            selectedSerie !== undefined
            && selectedColor1 !== undefined
            && selectedColor2 !== undefined
            && selectedOperationConcept !== undefined
        ) {
            const toolTipText = <div style={{backgroundColor: 'var(--weiss)'}}>
                <div className={"row"} style={{backgroundColor: 'var(--weiss)', color: 'var(--blueColor)'}}>
                    <div className={"col-2"}>Designlinie:</div>
                    <div className={"col-2"}>{selectedSerie.name}</div>
                </div>
                <div className={"row"} style={{backgroundColor: 'var(--weiss)', color: 'var(--blueColor)'}}>
                    <div className={"col-2"}>Bedienkonzept:</div>
                    <div className={"col-2"}>{selectedOperationConcept.name}</div>
                </div>
                <div className={"row"} style={{backgroundColor: 'var(--weiss)', color: 'var(--blueColor)'}}>
                    <div className={"col-2"}>Taster:</div>
                    <div className={"col-2"}>{selectedColor1.colorName}</div>
                </div>
                <div className={"row"} style={{backgroundColor: 'var(--weiss)', color: 'var(--blueColor)'}}>
                    <div className={"col-2"}>Rahmen:</div>
                    <div className={"col-2"}>{selectedColor2.colorName}</div>
                </div>
            </div>;

            if (picture) {
                return <HtmlTooltip enterTouchDelay={50} title={toolTipText}>
                    <div style={{padding: "5px", height: "60px", marginBottom: "-10px", marginTop: "-5px"}}>{generateCompositeImage("50px", selectedSerie, selectedColor1, selectedColor2, picture)}</div>
                </HtmlTooltip>;
            }
            return <HtmlTooltip enterTouchDelay={50} title={toolTipText}>
                <div style={{padding: "5px"}}>{generateCompositeImage("50px", selectedSerie, selectedColor1, selectedColor2)}</div>
            </HtmlTooltip>;
        }

        return null;
    }
    function hasFloorDifferent(floor) {
        let hasDifferent = false;
        floor.rooms.map(r => hasDifferent = hasDifferent || r.selectedDesign.selectedColor1.colorId !== currentDesign.selectedColor1.colorId)
        return hasDifferent;
    }
    function isFloorChanged(floor) {
        let isChanged = false;
        floor.rooms.map(r => isChanged = isChanged || roomHistorie[r.id] !== undefined);
        return isChanged;
    }
    // function hasFloorChanges(floor) {
    //     let result = false;
    //     floor.rooms.map(r => result = result || (
    //         r.selectedDesign.selectedSerie.id !== currentDesign.selectedSerie.id
    //         || !compareColor(r.selectedDesign.selectedColor1, currentDesign.selectedColor1)
    //         || !compareColor(r.selectedDesign.selectedColor2, currentDesign.selectedColor2)
    //     ));
    //     return result;
    // }
    function setNewDesignButton(floor) {
        // if (hasFloorChanges(floor)) {
            return <div className={"buttonSelectDesignForRoom"} onClick={e => selectForFloor(floor)}><ApplyIcon/> Auf
                alle Räume anwenden</div>;
        // }
        // return <></>;
    }
    // function resetDesignButton(floor) {
    //     return <div className={"buttonResetDesignForRoom"} onClick={e => resetForFloor(floor)}><ResetIcon /> Rückgängig machen</div>;
    // }
    const CircleButton = withStyles((theme) => ({
        root: {
            cursor: "pointer",
            borderRadius: "22px",
            width: "22px",
            height: "22px",
            textAlign: "center",
            border: "none",
            minWidth: "unset",
            overflow: "hidden",
            padding: "0 0 0 4px",
            margin: "0 7px 0 2px"
        },
    }))(Button);
    return <div className="floorWrapper" key={data.id + "xx" + floor.id}>
        <div className="floorBoxHeader">
            <div className="col-3">
                <div className="openClose" onClick={(e) => onOpenClose(e)}>
                    {open ? <CircleButton>
                        <img className="icon-to-page-bottom" src={uarrh} alt={""} width="25" style={{marginRight: "-22px"}} />
                        <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""} width="25"/>
                    </CircleButton> : <CircleButton>
                        <img className="icon-to-page-bottom" src={darrh} alt={""} width="25" style={{marginRight: "-22px"}} />
                        <img className="icon-to-page-bottom only-hover" src={darrh} alt={""} width="25"/>
                    </CircleButton> }
                </div>
                <LabelBox value={floor.name} editAble={false} isBold={true}/>
            </div>
            <div className={"text-center col-2"}>

            </div>
            <div className={"text-center col-4"} style={{padding:"5px"}}>
                {/*{isFloorChanged(floor) ? resetDesignButton(floor) : setNewDesignButton(floor)}*/}
                {isFloorChanged(floor) || hasFloorDifferent(floor) ? setNewDesignButton(floor) : null}
            </div>
        </div>
        {open ? generateRoomList() : null }
    </div>
}
