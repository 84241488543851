import React from "react";
import CountBox from "./CountBox/CountBox";
import LabelBox from "./LabelBox/LabelBox";

export default function RoomComponent({room, updateRoom, deleteRoom, showMouseOver, tasterCursor = null, col = 1, jumpTo}){


    function rename(name){
        updateRoom({...room, name: name});
    }
    function onDelete(e){
        deleteRoom(room);
    }
    function setDoorCount(value){
        updateRoom({...room, doorCount: value});
    }
    function setWindowCountCount(value){
        updateRoom({...room, windowCount: value, selectedEquipment: {...room.selectedEquipment, verschattung_steuern: value < 1 ? 0 : room.selectedEquipment.verschattung_steuern}});
    }
    function setHeatingCountCount(value){
        updateRoom({...room, heatingCount: value, selectedEquipment: {...room.selectedEquipment, heizen_heizkoerper: value < 1 ? 0 : room.selectedEquipment.heizen_heizkoerper}});
    }
    function setFloorheatingCountCount(value){
        updateRoom({...room, floorheatingCount: value, selectedEquipment: {...room.selectedEquipment, heizen_fußbodenheizung: value < 1 ? 0 : room.selectedEquipment.heizen_fußbodenheizung}});
    }

    return <div className={"roomRow row" + (showMouseOver ? " mouseover" : "")}>
        <div className="col-5 contextBoxHover">
            <LabelBox value={room.name} setValue={rename} deleteItem={onDelete}/>
        </div>
        <div className={"phone-w-50"}>
            <div className={"text-center col-3 w-188" + (col === 1 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={5} value={room.doorCount} setValue={setDoorCount} jumpTo={jumpTo}/></div>
            <div className={"text-center col-3 w-188" + (col === 2 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={room.windowCount} setValue={setWindowCountCount} jumpTo={jumpTo}/></div>
            <div className={"text-center col-3 w-188" + (col === 3 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={room.heatingCount} setValue={setHeatingCountCount} jumpTo={jumpTo}/></div>
            <div className={"text-center col-3 w-188" + (col === 4 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={room.floorheatingCount} setValue={setFloorheatingCountCount} jumpTo={jumpTo}/></div>
        </div>
    </div>;
}
